<template>
  <div>
    <b-link :to="'/' + postData[0].slug">
      <div
        v-bind:style="{ backgroundImage: 'url(' + postData[0].better_featured_image.source_url + ')' }"
        class="bkImage"
      > 
        <div class="outerImage">
          <div class="gradHome"></div>
          <div class="gradHomeSecond"></div>
          <!-- <img class="newsImage" :src="postCard.better_featured_image.source_url"> -->
          <div class="gradHomeThird"></div>
        </div>
      </div>
    </b-link>
    <b-link :to="'/' + postData[0].slug" class="newsLink">
      <div class="newsHeading" v-html="postData[0].title.rendered"></div>
    </b-link>
    <div class="newsContent" v-html="postData[0].excerpt.rendered"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "slider",
  props: ["Year", "posts", "develop", "config"],
  data() {
    return {
      postData: [],
      loading: ''
    };
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = this.img;
    }
  },
  mounted() {
    setTimeout(() => {
    axios //add API Call
      .get(this.posts + "?randomadd=" + new Date().getTime()) // Page number changed on each page based on JSON page id you are calling
      .then(response => 
      (this.postData = response.data),
      (this.loading = true),
      );},1000)
  }
};
</script>

<style scoped>
.bkImage {
  background-repeat: no-repeat;
  background-size: cover;
  height: 496px;
  background-position: center;
}
::v-deep .gradHome {
  background-color: #5897E6;
  background-image: linear-gradient(to right, #5897E6 , #A8B7C9);
  height: 19px;
  width: 80%;
  position: absolute;
  right: 15px;
  border-radius: 10px 0px 0 10px;
}
::v-deep .gradHomeSecond {
  background-color: #5897E6;
  background-image: linear-gradient(to right, #5897E6 , #A8B7C9);
  height: 19px;
  width: 40%;
  position: absolute;
  right: 15px;
  border-radius: 10px 0px 0 10px;
  top: 30px;
}
::v-deep .gradHomeThird {
  background-color: #5897E6;
  background-image: linear-gradient(to right, #5897E6 , #A8B7C9);
  height: 19px;
  width: 40%;
  position: absolute;
  border-radius: 0px 10px 10px 0px;
  margin-top: 25.6em;
}
.newsLink:hover {
  text-decoration: none;
}
.outerImage {
  /* max-height: 200px; */
  overflow: hidden;
}
.newsImage {
  width: 100%;
  margin-bottom: 20px;
  /* max-height: 200px; */
}
.newsTitle {
  color: #A8B7C9;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.newsHeading {
  color: #0A3F7F;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 15px;
  white-space: nowrap;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.newsHeading:hover {
  color: #5897E6;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 15px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.newsContent {
  color: #707070;
  font-size: 14px;
  font-weight: 300;
}
::v-deep .newsMain > p {
  white-space: nowrap; 
  /* width: 300px;  */
  overflow: hidden;
  text-overflow: ellipsis; 
}
::v-deep .btn-secondary:hover {
  border: 0px solid #0b3f7e !important;
  border-radius: 10px !important;
  color: #fff !important;
  background: #7aafef !important;
  font-weight: 400 !important;
  padding: 10px 60px !important;
  display: block !important;
}
a.btn.postButton.btn-secondary {
  color: #fff !important;
}
::v-deep .carousel-control-next {
  right: 0;
  display: none;
}
::v-deep .carousel-caption {
  color: white;
  background-color: rgba(94, 146, 210, 0.4);
  margin-left: -15%;
  margin-right: -15%;
  padding-top: 17%;
  height: 100%;
  text-align: left;
  bottom: 0;
}
::v-deep .developGreen > .carousel-caption {
  background-color: rgba(118, 195, 189, 0.4) !important;
}
#slider {
  padding-bottom: 2%;
  margin-top: -2em;
}
.postButton.developGreen {
  background-color: #76c3bd !important;
  border: 1px solid #76c3bd !important;
}
::v-deep .developGreen > .carousel-indicators .active {
  background-color: #76c3bd !important;
}
.postButton {
  border: 0px solid #0b3f7e;
  border-radius: 10px;
  background: #0b3f7e;
  font-weight: 400;
  padding: 10px 60px 10px 60px;
  display: block;
  width: 250px;
  color: #fff;
}
::v-deep .carousel-indicators li {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 1px solid #ccc;
}
::v-deep .carousel-indicators .active {
  background-color: #0b3f7e;
}
.newsMain {
  color: #fff;
  word-wrap: break-word;
  /* width: 700px; */
  margin-bottom: 30px;
}
.newsTitle {
  color: #fff;
  word-wrap: break-word;
  width: 700px;
  margin-bottom: 30px;
}
::v-deep .carousel-inner {
  height: 800px;
}
::v-deep .carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  display: none;
}

@media only screen and (max-width: 1200px) {
  ::v-deep .carousel-inner {
  height: 650px;
}
}

@media only screen and (max-width: 1024px) {
  ::v-deep .carousel-inner {
    max-height: 650px;
  }
}
@media only screen and (max-width: 990px) {
  .gradHome {
    display: none;
  }
  .gradHomeSecond {
    display: none;
  }
  .gradHomeThird {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .carousel-inner {
    max-height: 450px;
  }
  .newsHeading {
    color: #0A3F7F;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 15px;
    white-space: normal;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
  }
  .newsHeading:hover {
    color: #5897E6;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 15px;
    /* white-space: nowrap; */
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-decoration: none;
  }
}
@media only screen and (max-width: 480px) {
  ::v-deep .bkImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    background-position: center;
  }
  ::v-deep .newsMain > p {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ::v-deep .carousel-item {
    transition: transform 1.2s ease-in-out;
  }
  ::v-deep .carousel-inner {
    max-height: 250px;
  }
  .newsMain {
    font-size: 0.8rem;
    width: 400px;
  }
  ::v-deep .carousel-indicators li {
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 1px solid #ccc;
  }
  .postButton {
    padding: 5px 15px 5px 15px;
    width: 155px;
    margin-top: -6%;
    font-size: .8rem;
  }
  ::v-deep .newsTitle {
    font-size: 1rem!important;
    width: 100%;
    margin-bottom: 15px;
  }
  .newsMain {
    color: #fff;
    word-wrap: break-word;
    margin-bottom: 45px;
  }
}
</style>
