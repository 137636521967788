<template>
  <div class="sback" v-if="loading === true">
    <b-container>
      <div class="sponsors-footer-row-mobile">
        <template v-if='isMobile(true)'>
          <b-row>
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles TourPartners">Tour Partners</span>
              </h3>
              <b-row>
                <b-col cols="6" v-for="(spone, index) in sponsors" :key="index">
                  <a :href="spone.acf.sponsor_url">
                    <b-img
                      class="spLogo tourPartners"
                      :src="spone.acf.image"
                    ></b-img>
                  </a>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles TourPartners">Home of Asian golf</span>
              </h3>
              <b-row>
                <b-col>
                  <a :href="secondsponsors[0].acf.sponsor_url">
                    <b-img
                      class="spLogo sentosaLogo"
                      :src="secondsponsors[0].acf.image"
                    ></b-img>
                  </a>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles TourPartners">Affliliate</span>
              </h3>
              <b-row>
                <b-col>
                  <a :href="secondsponsors[1].acf.sponsor_url">
                    <b-img
                      class="spLogo"
                      :src="secondsponsors[1].acf.image"
                    ></b-img>
                  </a>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles"> Web Partner </span>
              </h3>
              <a :href="thirdsponsors[0].acf.sponsor_url">
                <b-img class="spLogo" :src="thirdsponsors[0].acf.image"></b-img>
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles"> Media Partners </span>
              </h3>
              <b-row class="mediaPartners-Logos">
                <b-col
                  cols="6"
                  v-for="(spfour, index) in fourthsponsors"
                  :key="index"
                  ><a :href="spfour.acf.sponsor_url">
                    <b-img class="spLogo" :src="spfour.acf.image"></b-img
                  ></a>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row>
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles TourPartners">Tour Partners</span>
              </h3>
              <b-row>
                <b-col v-for="(spone, index) in sponsors" :key="index">
                  <a :href="spone.acf.sponsor_url">
                    <b-img
                      class="spLogo tourPartners"
                      :src="spone.acf.image"
                    ></b-img>
                  </a>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles TourPartners">Home of Asian golf</span>
              </h3>
              <b-row>
                <b-col>
                  <a :href="secondsponsors[0].acf.sponsor_url">
                    <b-img
                      class="spLogo sentosaLogo"
                      :src="secondsponsors[0].acf.image"
                    ></b-img>
                  </a>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles TourPartners">Affliliate</span>
              </h3>
              <b-row>
                <b-col>
                  <a :href="secondsponsors[1].acf.sponsor_url">
                    <b-img
                      class="spLogo"
                      :src="secondsponsors[1].acf.image"
                    ></b-img>
                  </a>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="sponsors">
            <b-col>
              <h3 class="Footertitle">
                <span class="sponsorTitles"> Media Partners </span>
              </h3>
              <b-row class="mediaPartners-Logos">
                <b-col
                  v-for="(spfour, index) in fourthsponsors"
                  :key="index"
                  ><a :href="spfour.acf.sponsor_url">
                    <b-img class="spLogo" :src="spfour.acf.image"></b-img
                  ></a>
                </b-col>
              </b-row>
            </b-col>
            <b-col >
              <h3 class="Footertitle">
                <span class="sponsorTitles"> Web Partner </span>
              </h3>
              <a :href="thirdsponsors[0].acf.sponsor_url">
                <b-img class="spLogo" :src="thirdsponsors[0].acf.image"></b-img>
              </a>
            </b-col>
          </b-row>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "sponsors",
  props: ["sponsor", "develop"],
  data() {
    return {
      sponsors: [],
      secondsponsors: [],
      thirdsponsors: [],
      fourthsponsors: [],
      loading: ''
    };
  },
  methods: {
    isMobile() {
      if( screen.width <= 990 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  mounted() {
    if (this.$route.name === "home") {
      axios //add API Call
        .get(this.sponsor + "firstsponsors?_embed") // Page number changed on each page based on JSON page id you are calling
        .then((response) => {
          this.sponsors = response.data;
          this.loading = true;
          return axios.get(this.sponsor + "secondsponsors?_embed");
        })
        .then((response) => {
          this.secondsponsors = response.data;
          this.loading = true;
          return axios.get(this.sponsor + "thirdsponsors?_embed");
        })
        .then((response) => {
          this.thirdsponsors = response.data;
          this.loading = true;
          return axios.get(this.sponsor + "fourthsponsors?_embed");
        })
        .then((response) => {
          this.fourthsponsors = response.data;
          this.loading = true;
        });
    } else if (this.$route.name === "adt") {
      axios //add API Call
        .get(this.sponsor + "sponsors?_embed") // Page number changed on each page based on JSON page id you are calling
        .then((response) => {
          this.sponsors = response.data;
          this.loading = true;
        });
    }
  },
};
</script>

<style scoped>
img.spLogo.tourPartners:first-child {
  width: 135px;
}
::v-deep img.spLogo.tourPartners:nth-child(2) {
  width: 135px;
}
img.sentosaLogo {
  margin-top: 19px;
  /* margin-left: 6em; */
}
span.sponsorTitles {
  display: block;
  font-size: 19px;
  color: #0A3F7F;
  font-weight: 500;
  padding-bottom: 15px;
}
span.affiliate {
  margin-left: 90px;
}
.spLogo {
  width: 150px;
  mix-blend-mode: multiply;
}
.col {
  text-align: center;
}
.sponsors {
  background-color: #f1f1f1;
  padding-top: 2%;
  width: 100%;
  margin: auto;
}
.sback {
  background-color: #f1f1f1;
  color: #0b3f7e;
}
.title {
  border-bottom: 1px solid #a5a5a5;
  font-size: 19px;
}
.Footertitle {
  border-bottom: 1px solid #a5a5a5;
  font-size: 19px;
}
.adt {
  padding: 5% 0;
}
.sponsors-footer-row {
  padding-top: 110px !important;
  padding-bottom: 90px !important;
  padding-left: 0;
}
.sponsors-footer-row > div {
  padding-bottom: 40px;
}
.sponsors-footer-row-mobile > div {
  padding-bottom: 40px;
}
.sponsors-footer-row-mobile {
  padding-top: 60px !important;
  padding-bottom: 0px !important;
  padding-left: 0;
}
@media only screen and (max-width: 990px) {
  ::v-deep img.sentosaLogo {
    margin-left: 0em !important;
  }
  .MobNo {
    display: none;
  }
  ::v-deep img.spLogo.tourPartners:first-child {
    width: 80px;
  }
  ::v-deep .spLogo {
    width: 80px;
  }
}
@media only screen and (max-width: 425px) {
  span.affiliate {
    margin-left: 0px;
  }
  span.sponsorTitles {
    padding-bottom: 15px;
    display: block;
    font-size: 10pt;
  }
  .mediaPartners-Logos {
    margin-left: -23px;
  }
}
@media only screen and (max-width: 375px) {
  .TourPartners {
    padding-bottom: 31px!important;
  }
}
</style>
